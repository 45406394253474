import { gql } from '@biletiniz/apollo';

export const BUS_BOOKING_INFO_QUERY = gql`
  query busBookingInfo($session: String!) {
    busBookingInfo(session: $session) {
      busOfferId
      session
      tel
      email
      totalPrice
      cardHolderName
      cardNumber
      cvc
      expire {
        year
        month
      }
      utmSource
      marker
      passengers {
        seatNo
        name
        surname
        hesCode
        passportNo
        passportCountry {
          label
          value
        }
        identificationNumber
        tcdegil
        gender
      }
    }
  }
`;
