import { gql } from '@biletiniz/apollo';

export const ALL_BUS_STATIONS_QUERY = gql`
  query busTypeaheadSearch($keyword: String) {
    busTypeaheadSearch(keyword: $keyword) {
      label
      value
    }
  }
`;
