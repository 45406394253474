import { gql } from '@biletiniz/apollo';

export const CANCEL_BUS_TICKET_SELL_MUTATION = gql`
  mutation cancelBusTicketSell(
    $PNRNumber: String!
    $seatNo: Int!
    $amount: Int!
    $lastName: String!
    $buyerEmail: String!
    $tel: String!
    $lastTicket: Boolean!
  ) {
    cancelBusTicketSell(
      filter: {
        PNRNumber: $PNRNumber
        lastName: $lastName
        tel: $tel
        buyerEmail: $buyerEmail
        seatNo: $seatNo
        amount: $amount
        lastTicket: $lastTicket
      }
    )
  }
`;
