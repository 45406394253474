import { gql } from '@biletiniz/apollo';

export const BUS_OFFERS_QUERY = gql`
  query BusOffer($session: String!, $busOfferId: String!) {
    busOffer(session: $session, busOfferId: $busOfferId) {
      id
      expeditionDetails {
        id
        duration {
          type
          value
        }
        busSeatPlacementType
        lineNumber
        trackingNumber
        tripType
        isFulljourney
        maximumReserveDateTime
        availableSeats
        ticketCancelable
        cancelableBefore
        route
      }
      company {
        number
        name
        logoUrl
      }
      departure {
        name
        value
        hour
        at
      }
      arrival {
        name
        value
      }
      price {
        currency {
          code
        }
        total
      }
      busFeatures {
        number
        description
        details
        icon
      }
    }
  }
`;
