import { gql } from '@biletiniz/apollo';

export const BUS_SEND_EMAIL_QUERY = gql`
  query busSendEmail(
    $firstPassengerSurname: String!
    $pnr: String!
    $email: String
  ) {
    busSendEmail(
      firstPassengerSurname: $firstPassengerSurname
      pnr: $pnr
      email: $email
    ) {
      isSend
    }
  }
`;
