import { gql } from '@biletiniz/apollo';

export const RESERVE_SEAT_MUTATION = gql`
  mutation seatReserving(
    $session: String!
    $busOfferId: String!
    $companyNumber: Int!
    $origin: Int!
    $destination: Int!
    $departureAt: Date!
    $hour: DateTime!
    $lineNumber: Int!
    $trackingNumber: String!
    $passengers: [BusPassengerInput]!
    $telefonNo: String!
    $gender: String!
    $numberOfPassengers: Int!
    $buyerEmail: String!
  ) {
    seatReserving(
      filter: {
        session: $session
        busOfferId: $busOfferId
        companyNumber: $companyNumber
        origin: $origin
        destination: $destination
        departureAt: $departureAt
        hour: $hour
        lineNumber: $lineNumber
        trackingNumber: $trackingNumber
        passengers: $passengers
        telefonNo: $telefonNo
        gender: $gender
        numberOfPassengers: $numberOfPassengers
        buyerEmail: $buyerEmail
      }
    ) {
      responseResult
      PNR
      expeditionDateTime
      maximumBookingTime
      message
    }
  }
`;
