import { gql } from '@biletiniz/apollo';

export const FLIGHT_OFFER_PACKAGES_QUERY = gql`
  query flightOfferPackages($session: String!, $flightOfferId: String!) {
    flightOfferPackages(session: $session, id: $flightOfferId) {
      packages {
        packageClass
        packageCode
        packageTitle
        prices {
          passengerFares {
            code
            quantity
            basePrice
            taxesPrice
            totalPrice
            serviceFeesPrice
          }
          packagePrice
          passengerQuantity
          passengerType
          currency
        }
        rules {
          sharedRules {
            text
            status
          }
          passengerRules {
            passengerType
            checkedBaggage {
              quantity
              pieces
              unit
            }
            carryOnBaggage {
              quantity
              pieces
              unit
            }
          }
        }
      }
    }
  }
`;
