import { gql } from '@biletiniz/apollo';

export const ALL_BUS_OFFERS_QUERY = gql`
  query allBusStationOffers(
    $origin: Int!
    $destination: Int!
    $departureDate: Date!
    $session: String
  ) {
    allBusStationOffers(
      filter: {
        origin: $origin
        destination: $destination
        departureDate: $departureDate
      }
      session: $session
    ) {
      session
      sessionTTL
      busOffers {
        id
        expeditionDetails {
          id
          duration {
            type
            value
          }
          busSeatPlacementType
          lineNumber
          trackingNumber
          tripType
          isFulljourney
          maximumReserveDateTime
          availableSeats
          ticketCancelable
          cancelableBefore
          route
        }
        company {
          number
          name
          logoUrl
        }
        departure {
          name
          value
          hour
          at
        }
        arrival {
          name
          value
        }
        price {
          currency {
            code
          }
          total
        }
        busFeatures {
          number
          description
          details
          icon
        }
      }
    }
  }
`;
