import { gql } from '@biletiniz/apollo';

export const SEND_SUPPORT_EMAIL = gql`
  mutation sendSupportEmail(
    $serviceType: String!
    $name: String!
    $email: String!
    $gsmNumber: String!
    $pnr: String
    $message: String!
  ) {
    sendSupportEmail(
      serviceType: $serviceType
      name: $name
      email: $email
      gsmNumber: $gsmNumber
      pnr: $pnr
      message: $message
    ) {
      isSend
    }
  }
`;
