import { useEffect, useState, useMemo } from '@biletiniz/ui-core';
import { useRouter as useNextRouter } from 'next/router';
import { qs, formatISO, addHours } from '@biletiniz/shared';

export const useRouter = () => {
  const router = useNextRouter();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (router.isReady) {
      setIsReady(true);
    }
  }, [router.isReady, router.asPath]);

  const query = useMemo(() => qs.parse(router.asPath.split('?')[1]), [
    isReady,
    router.asPath,
  ]);
  const flightOffersSearchForm = useMemo(
    () => ({
      returnDate: query?.returnDate ? query?.returnDate : null,
      departureDate: query?.departureDate
        ? query?.departureDate
        : formatISO(addHours(new Date(), 8), { representation: 'date' }),
      origin:
        query?.origin?.label && query?.origin?.value
          ? {
              value: '',
              label: '',
              ...query?.origin,
              isCity: query?.origin?.isCity === 'true',
            }
          : null,
      destination:
        query?.destination?.label && query?.destination?.value
          ? {
              value: '',
              label: '',
              ...query?.destination,
              isCity: query?.destination?.isCity === 'true',
            }
          : null,
      cabinClassTravellers: {
        cabinClass: query?.cabinClassTravellers?.cabinClass || 'Economy',
        travellers: {
          adultsCount:
            parseInt(query.cabinClassTravellers?.travellers?.adultsCount) || 1,
          childrenCount:
            parseInt(query.cabinClassTravellers?.travellers?.childrenCount) ||
            0,
          infantsCount:
            parseInt(query.cabinClassTravellers?.travellers?.infantsCount) || 0,
        },
      },
      sc: parseInt(query.sc),
    }),
    [
      query?.departureDate,
      query?.returnDate,
      query?.origin,
      query?.destination,
      query?.cabinClassTravellers?.cabinClass,
      query.cabinClassTravellers?.travellers,
      query.sc,
    ]
  );

  const flightOfferFilterForm = useMemo(
    () => ({
      stopsNumber: query?.stopsNumber,
      flightNumber: query?.flightNumber,
      directions: query?.directions
        ? query?.directions?.map(
            ({
              elapsedMinTime,
              elapsedMaxTime,
              departureMinDate,
              departureMaxDate,
              arrivalMinDate,
              arrivalMaxDate,
            }) => ({
              elapsedMinTime: elapsedMinTime ? parseInt(elapsedMinTime) : null,
              elapsedMaxTime: elapsedMinTime ? parseInt(elapsedMaxTime) : null,
              departureMaxDate,
              departureMinDate,
              arrivalMinDate,
              arrivalMaxDate,
            })
          )
        : [undefined, undefined],
      transitAirports: query?.transitAirports,
      airlines: query?.airlines,
    }),
    [
      query?.stopsNumber,
      query?.flightNumber,
      query?.directions,
      query?.transitAirports,
      query?.airlines,
    ]
  );

  const selectedFlightOffer = useMemo(
    () => ({
      flightOfferId: query.flightOfferId,
      session: query.session,
      pnr: query?.pnr,
      providerType: query?.providerType,
      firstPassengerSurname: query?.firstPassengerSurname,
      firstPassengerGivenName: query?.firstPassengerGivenName,
    }),
    [
      query?.flightOfferId,
      query?.session,
      query?.pnr,
      query?.providerType,
      query?.firstPassengerSurname,
    ]
  );

  const flightReservationInformation = useMemo(
    () => ({
      pnr: query.pnr,
      firstPassengerSurname: query.firstPassengerSurname,
      firstPassengerGivenName: query.firstPassengerGivenName,
      flightProviderType: query.flightProviderType,
    }),
    [query?.pnr, query?.firstPassengerSurname, query?.flightProviderType]
  );

  const flightReservation = useMemo(
    () => ({
      pnr: query.pnr,
      firstPassengerSurname: query.firstPassengerSurname,
      firstPassengerGivenName: query.firstPassengerGivenName,
      flightProviderType: query.flightProviderType,
    }),
    [query?.pnr, query?.firstPassengerSurname, query?.flightProviderType]
  );

  const busReservationInformation = useMemo(
    () => ({
      pnr: query.pnr,
      firstPassengerSurname: query.firstPassengerSurname,
    }),
    [query?.pnr, query?.firstPassengerSurname]
  );

  const busReservation = useMemo(
    () => ({
      pnr: query.pnr,
      firstPassengerSurname: query.firstPassengerSurname,
    }),
    [query?.pnr, query?.firstPassengerSurname]
  );

  const flightOffersUpdatedBy = useMemo(() => query.flightOffersUpdatedBy, [
    query?.flightOffersUpdatedBy,
  ]);

  const busOffersUpdatedBy = useMemo(() => query.busOffersUpdatedBy, [
    query?.busOffersUpdatedBy,
  ]);

  const busOffersSearchForm = useMemo(
    () => ({
      departureDate: query?.departureDate
        ? query?.departureDate
        : formatISO(addHours(new Date(), 2), { representation: 'date' }),
      origin:
        query?.origin || query?.originName
          ? {
              value: query?.origin,
              label: query?.originName,
            }
          : null,
      destination:
        query?.destination || query?.destinationName
          ? {
              value: query?.destination,
              label: query?.destinationName,
            }
          : null,
      sc: parseInt(query.sc),
    }),
    [query?.departureDate, query?.origin, query?.destination, query.sc]
  );

  const selectedBusOffer = useMemo(
    () => ({
      gender: query?.gender,
      seats: query?.seats,
      pnr: query?.pnr,
      firstPassengerSurname: query?.firstPassengerSurname,
      session: query?.session,
      id: query?.id,
    }),
    [query?.gender, query?.seats, query?.pnr, query?.firstPassengerSurname]
  );

  const selectedBusOfferId = useMemo(
    () => ({
      session: query?.session,
      id: query?.id,
      marker: query?.marker,
      utmSource: query?.utm_source,
    }),
    [query?.session, query?.id]
  );

  const sessionPF = useMemo(() => query?.sessionPF, [query?.sessionPF]);

  const busPnrQueryForm = useMemo(
    () => ({
      surname: query?.surname ?? '',
      pnr: query?.pnr ?? '',
    }),
    [query?.surname, query?.pnr]
  );

  const flightPnrQueryForm = useMemo(
    () => ({
      firstPassengerSurname: query?.firstPassengerSurname ?? '',
      firstPassengerGivenName: query?.firstPassengerGivenName ?? '',
      flightProviderType: query?.flightProviderType ?? '',
      pnr: query?.pnr ?? '',
    }),
    [
      query?.firstPassengerSurname,
      query?.firstPassengerGivenName,
      query?.flightProviderType,
      query?.pnr,
    ]
  );

  const visaType = useMemo(() => query?.visaType, [query?.visaType]);

  return {
    isReady,
    flightOffersSearchForm,
    flightOfferFilterForm,
    selectedFlightOffer,
    flightReservation,
    flightReservationInformation,
    busReservation,
    busReservationInformation,
    flightOffersUpdatedBy,
    busOffersUpdatedBy,
    router,
    busOffersSearchForm,
    selectedBusOffer,
    selectedBusOfferId,
    busPnrQueryForm,
    flightPnrQueryForm,
    sessionPF,
    visaType,
  };
};
