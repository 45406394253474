import { gql } from '@biletiniz/apollo';

export const FLIGHT_OFFER_QUERY = gql`
  query FlightOffer($session: String!, $flightOfferId: String!) {
    flightOffer(session: $session, id: $flightOfferId) {
      id
      providerType
      providerContext
      isDirectionLevel
      isReservable
      defaultPackage
      price {
        passengerFares {
          code
          quantity
          basePrice
          taxesPrice
          totalPrice
          serviceFeesPrice
        }
        currency
        basePrice
        serviceFeesPrice
        taxesPrice
        totalPrice
      }
      directions {
        providerContext
        elapsedTime
        defaultPackage
        price {
          passengerFares {
            code
            quantity
            basePrice
            taxesPrice
            totalPrice
            serviceFeesPrice
          }
          currency
          basePrice
          taxesPrice
          totalPrice
          serviceFeesPrice
        }
        packages {
          packageClass
          packageCode
          packageTitle
          prices {
            passengerFares {
              code
              quantity
              basePrice
              taxesPrice
              totalPrice
              serviceFeesPrice
            }
            packagePrice
            passengerQuantity
            passengerType
            currency
          }
          rules {
            sharedRules{
              text
              status
            }
            passengerRules{
              passengerType
              checkedBaggage {
                quantity
                pieces
                unit
              }
              carryOnBaggage {
                quantity
                pieces
                unit
              }
            }
          }
        }
        segments {
          elapsedTime
          flightNumber
          baggageUnit
          baggageQuantity
          cabinClass {
            code
            name
          }
          operatingAirline {
            code
            name
            logoUrl
          }
          airplane {
            code
            name
          }
          departureAirport {
            code
            name
          }
          arrivalAirport {
            code
            name
          }

          departureAt
          arrivalAt
        }
      }
    }
  }
`;
