import { gql } from '@biletiniz/apollo';

export const BUS_EXTRA_INFORMATION_QUERY = gql`
  query busExtraInformation(
    $companyNumber: Int!
    $origin: Int!
    $destination: Int!
    $departureAt: DateTime!
    $hour: DateTime!
    $lineNumber: Int!
    $operationType: Int!
    $trackingNumber: String!
  ) {
    busExtraInformation(
      filter: {
        companyNumber: $companyNumber
        origin: $origin
        destination: $destination
        departureAt: $departureAt
        hour: $hour
        lineNumber: $lineNumber
        operationType: $operationType
        trackingNumber: $trackingNumber
      }
    ) {
      busSeatsMap {
        number
        status
        statusSide
        reservationStatus
        price
      }
      busInformation {
        reservable
        sellable
        _3DSecureRequired
        _3DSecureActive
        TCRequired
        notReservableCause
        doableWithPassport
        DifferentGenderChoosable
        hesCodeRequired
        doubleSeatSoldableSinglePassenger
        singleOccupiedDoubleSellable
        ticketCancelable
        cancelableBefore
        singleSeatDifference
      }
      paymentRules {
        _3DSecureActive
        _3DSecureRequired
        openCashPaymentActive
        prepaymentActive
        parakodPaymentActivate
        bkmPaymentActive
        hopiActive
        masterpassActive
        fastPayPaymentActive
        garantiPayPaymentActive
      }
    }
  }
`;
