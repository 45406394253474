import { gql } from '@biletiniz/apollo';

export const ALL_AIRPORTS_QUERY = gql`
  query flightTypeaheadSearch($keyword: String) {
    flightTypeaheadSearch(keyword: $keyword) {
      label
      value
      cityCode
      isCity
    }
  }
`;
