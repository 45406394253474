import { gql } from '@biletiniz/apollo';

export const DEPARTURE_POINTS_QUERY = gql`
  query departurePoints(
    $companyNumber: Int!
    $lineNumber: Int!
    $origin: Int!
    $destination: Int!
    $trackingNumber: String!
    $departureDate: Date!
  ) {
    departurePoints(
      filter: {
        companyNumber: $companyNumber
        lineNumber: $lineNumber
        origin: $origin
        destination: $destination
        trackingNumber: $trackingNumber
        departureDate: $departureDate
      }
    ) {
      departurePointName
      DepartureHour
      departurePointCode
    }
  }
`;
