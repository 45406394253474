import { gql } from '@biletiniz/apollo';

export const CANCEL_BUS_RESERVATION_MUTATION = gql`
  mutation cancelBusReservation(
    $PNRNumber: String!
    $lastName: String!
    $tel: String!
    $buyerEmail: String!
    $seatNo: Int!
  ) {
    cancelBusReservation(
      filter: {
        PNRNumber: $PNRNumber
        lastName: $lastName
        tel: $tel
        buyerEmail: $buyerEmail
        seatNo: $seatNo
      }
    )
  }
`;
