import { gql } from '@biletiniz/apollo';

export const VISA_STORE_DATA_MUTATION = gql`
  mutation visaStoreData(
    $session: String!
    $passengers: [VisaPassengerInput]!
    $paymentCard: PaymentCardInput
    $visaType: String!
  ) {
    visaStoreData(
      session: $session
      passengers: $passengers
      paymentCard: $paymentCard
      visaType: $visaType
    )
  }
`;
