import { gql } from '@biletiniz/apollo';

export const BOOK_BUS_OFFER_MUTATION = gql`
  mutation busOfferBooking(
    $busOfferId: String
    $session: String!
    $companyNumber: Int!
    $origin: Int!
    $destination: Int!
    $departureAt: Date!
    $hour: DateTime!
    $lineNumber: Int!
    $trackingNumber: String!
    $passengers: [BusPassengerInput]!
    $telefonNo: String!
    $gender: String!
    $numberOfPassengers: Int!
    $buyerEmail: String!
    $pnr: String
    $prepaymentActive: Int
    $paymentCard: PaymentCardInput!
    $utmSource: String
    $marker: String
  ) {
    busOfferBooking(
      filter: {
        busOfferId: $busOfferId
        session: $session
        companyNumber: $companyNumber
        origin: $origin
        destination: $destination
        departureAt: $departureAt
        hour: $hour
        lineNumber: $lineNumber
        trackingNumber: $trackingNumber
        passengers: $passengers
        telefonNo: $telefonNo
        gender: $gender
        numberOfPassengers: $numberOfPassengers
        buyerEmail: $buyerEmail
        pnr: $pnr
        prepaymentActive: $prepaymentActive
        paymentCard: $paymentCard
        utmSource: $utmSource
        marker: $marker
      }
    )
  }
`;
