import { gql } from '@biletiniz/apollo';

export const BOOK_FLIGHT_OFFER_MUTATION = gql`
  mutation flightOfferBooking(
    $session: String!
    $flightOfferId: String
    $telephone: String!
    $email: String!
    $passengers: [PassengerInput]!
    $paymentCard: PaymentCardInput!
    $packagesCode: [String]
    $pnr: String!
    $providerType: String!
    $isReservationPurchase: Boolean!
  ) {
    flightOfferBooking(
      session: $session
      id: $flightOfferId
      passengers: $passengers
      telephone: $telephone
      paymentCard: $paymentCard
      email: $email
      packagesCode: $packagesCode
      pnr: $pnr
      providerType: $providerType
      isReservationPurchase: $isReservationPurchase
    )
  }
`;
