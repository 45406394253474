import { gql } from '@biletiniz/apollo';

export const FLIGHT_OFFER_QUERY = gql`
  query FlightOffer($session: String!, $id: String!) {
    flightOffer(session: $session, id: $id) {
      id
      providerType
      providerContext
      price {
        passengerFares {
          code
          quantity
          basePrice
          taxesPrice
          totalPrice
          serviceFeesPrice
        }
        currency
        basePrice
        serviceFeesPrice
        taxesPrice
        totalPrice
      }
      directions {
        providerContext
        elapsedTime
        segments {
          elapsedTime
          flightNumber
          baggageUnit
          baggageQuantity
          cabinClass {
            code
            name
          }
          operatingAirline {
            code
            name
            logoUrl
          }
          airplane {
            code
            name
          }
          departureAirport {
            code
            name
          }
          arrivalAirport {
            code
            name
          }

          departureAt
          arrivalAt
        }
      }
    }
  }
`;
