import { gql } from '@biletiniz/apollo';

export const PNR_DETAILS_QUERY = gql`
  query pnrDetails($PNRNumber: String!, $lastNameOrTelephoneNumber: String!) {
    pnrDetails(
      filter: {
        PNRNumber: $PNRNumber
        lastNameOrTelephoneNumber: $lastNameOrTelephoneNumber
      }
    ) {
      pnrInformations {
        id
        code
        firstName
        lastName
        email
        tel
        tc
        invoiced
        companyNumber
        gender
        maximumReserveDateTime
      }
      segmentDetails {
        companyName
        companyNumber
        companyLogoUrl
        lineNumber
        trackingNumber
        departure
        arrival
        departureCode
        arrivalCode
        departureDate
        arrivalDate
        duration {
          type
          value
        }
        tripType
        vehicleType
        platformNumber
        hour
        ticketCancelable
        cancelableBefore
      }
      passengersDetails {
        name
        surname
        identificationNumber
        passportNo
        passportCountry {
          label
          value
        }
        tcdegil
        hesCode
        seatNo
        ticketNo
        ticketPrice
        gender
        status
        statusDate
      }
      totalTicketPrice {
        unPaidAmount
        paidAmount
        currency
      }
    }
  }
`;
