import { gql } from '@biletiniz/apollo';

export const FLIGHT_PNR_DETAILS_QUERY = gql`
  query flightPnrDetails(
    $firstPassengerSurname: String
    $firstPassengerGivenName: String
    $pnr: String!
    $flightProviderType: String!
    $transparent: Boolean
  ) {
    flightPnrDetails(
      surname: $firstPassengerSurname
      givenName: $firstPassengerGivenName
      pnr: $pnr
      flightProviderType: $flightProviderType
      transparent: $transparent
    ) {
      providerType
      pnrInformations {
        code
        ticketTimeLimit
        createDateTime
        email
        tel
        firstName
        lastName
      }
      flightDetails {
        directions {
          segments {
            flightNumber
            cabinClass {
              code
              name
            }
            departureAt
            departureAirport {
              code
              name
            }
            airplane {
              code
              name
            }
            arrivalAt
            arrivalAirport {
              code
              name
            }
            operatingAirline {
              code
              name
              logoUrl
            }
          }
        }
      }
      passengersDetails {
        firstName
        lastName
        birthDate
        passengerTypeCode
        eTicketNumber
        baggageQuantity
        baggageUnit
      }
      totalPrice {
        amount
        currency
      }
    }
  }
`;
