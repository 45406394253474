import { gql } from '@biletiniz/apollo';

export const RESERVE_FLIGHT_OFFER_MUTATION = gql`
  mutation flightOfferReserving(
    $session: String!
    $flightOfferId: String!
    $telephone: String!
    $email: String!
    $packagesCode: [String]!
    $passengers: [PassengerInput]!
  ) {
    flightOfferReserving(
      session: $session
      id: $flightOfferId
      passengers: $passengers
      telephone: $telephone
      email: $email
      packagesCode: $packagesCode
    ) {
      responseResult
      PNR
      givenName
      surname
      expeditionDateTime
      maximumBookingTime
      message
      providerType
    }
  }
`;
