import { gql } from '@biletiniz/apollo';

export const FLIGHT_SEND_EMAIL_QUERY = gql`
  query flightSendEmail(
    $firstPassengerSurname: String!
    $firstPassengerGivenName: String!
    $pnr: String!
    $providerType: String!
    $email: String
  ) {
    flightSendEmail(
      firstPassengerSurname: $firstPassengerSurname
      firstPassengerGivenName: $firstPassengerGivenName
      pnr: $pnr
      providerType: $providerType
      email: $email
    ) {
      isSend
    }
  }
`;
